// extracted by mini-css-extract-plugin
export const root = "contact-module--root--1bAIZ";
export const leftColumn = "contact-module--leftColumn--2Uhmq";
export const rightColumn = "contact-module--rightColumn--RtIU0";
export const modal = "contact-module--modal--1SFd4";
export const modalDarkMode = "contact-module--modalDarkMode--3Mx5v";
export const isVisible = "contact-module--isVisible--2dN9n";
export const darkMode = "contact-module--darkMode--J4Oif";
export const title = "contact-module--title--3qU8t typography-module--title1--3kWAX";
export const topRow = "contact-module--topRow--1EJI2";
export const middleRow = "contact-module--middleRow--eQYrV";
export const bottomRow = "contact-module--bottomRow--bDESI";
export const button = "contact-module--button--3PlKs typography-module--large--1Oqox";
export const buttonDarkMode = "contact-module--buttonDarkMode--OxsXd";